.rounded-select__control {

  padding: 8px;

  @media (min-width: 1750px) {
    font-size: 18px; 
    padding: 10px; 
  }

  @media (min-width: 900px) and (max-width: 1750px) {
    font-size: 14px; 
    padding: 4px; 
  }

  @media (max-width: 900px) {
    font-size: 14px; 
    padding: 4px; 
  }
}

.rounded-select__menu {

  font-size: 18px; 

  @media (min-width: 1750px) {
    font-size: 18px; 
    padding: 6px; 
  }

  @media (min-width: 900px) and (max-width: 1750px) {
    font-size: 14px;
    padding: 4px; 
  }

  @media (max-width: 900px) {
    font-size: 14px; 
    padding: 2px; 
  }
}


.rounded-select__option {
  padding: 8px; 

  @media (min-width: 1300px) {
    padding: 6px; 
  }

  @media (min-width: 900px) and (max-width: 1300px) {
    padding: 4px; 
  }

  @media (max-width: 900px) {
    padding: 2px; 
  }
}
