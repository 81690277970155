
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
.permission-header{
    background-color: rgba(4, 159, 219, 0.1);
    font-weight: 700;
    padding: 16px 24px;
    // font-size: 16px;
}
.permission-table{
  border: 1px solid #E3E3E3;
  .permission-block {
    // font-size: 12px;
    padding: 14px 36px;
  }
  .role{
    // font-size: 16px;
    font-weight: 700;
    padding: 12px;
    border-radius: 10px ;
    color: #8E8E8E;
    margin-bottom: 20px;
    cursor: pointer;
    &:last-child{
      margin-bottom: 0;
    }
    .role-pill{
      font-size: 8px;
      padding: 4px 10px;
      margin: -25px 0 0 -10px;
    }
   
  }
  .active-role{
     background-color: rgba(4, 159, 219, 0.1);
     color: #049FDB;
  }
  .permission-group{
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #E3E3E3;
    &:last-child {
      border-bottom: none;
    }
    h6{
      font-weight: 700;
      // font-size: 16px;
      color: #8E8E8E;
    }
  }
}
.permission-tab-wrapper{
  .rc-tabs-nav-scroll,
  .rc-tabs-nav{
    width: 100% !important;
  }
  .rc-tabs-nav-wrap{
    padding: 0;
  }
  .rc-tabs-top{
    max-width: 100%;
    .rc-tabs-bar{
      border-bottom-color: #E3E3E3;
    }
  }
}

.permission-tab{
  width: 100%;
  .rc-tabs-nav{
    > div{
      &:first-child {
        display: flex;
      }
    }
    .rc-tabs-tab{
      color: #8E8E8E;
      font-weight: 700;
      text-transform: capitalize;
      width: 31.87%;
      text-align: center;
      padding: 19px 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .rc-tabs-tab-active{
      color: #049FDB;
    }
    .rc-tabs-ink-bar{
      height: 3px;
      border-radius: 0;
      background-color: #049FDB
    }
  }
  .detail-panel {
    padding: 20px;
    .panel-header{
      margin-bottom: 20px;
    }
    form{
      gap:35px
    }
    .form-group{
      margin-bottom: 0;
    }
    label {
      // font-size: 16px;
      // font-weight: 700;
      color: #000;
    }
  }
}
.cms-permission-panel{
  .content{
    padding: 30px 36px;
    border-bottom: 1px solid #E3E3E3;
  }
}
.custom-switch {
  height: 30px;
  padding: 0;
  
  .custom-control-input {
    position: absolute;
    z-index: -1; // Put the input behind the label so it doesn't overlay text
    opacity: 0;
    cursor: pointer;
    &:checked ~ .custom-control-label::before {
      background-color: #049FDB;
      border-color: #049FDB;
    }

    &:focus ~ .custom-control-label::before {
      box-shadow: none;
    }
  }
  .custom-control-label {
    padding: 0 0 0 55px;
    position: relative;
    font-weight: 700;
    // font-size: 16px;
    color: #8E8E8E;
    letter-spacing: 0;
    cursor: pointer;
    &::before { 
      width: 39px;
      height: 25px;
      border-radius: 1rem;
      left: 0;
      top: 0;
     }
    &::after { 
      top: 3px;
      left: 4px;
      height: 19px;
      width: 20px;
      border-radius: 50%;
     }
  }
}

.page-header{
  padding: 20px;
  .page-header-inner {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 20px;
    h1{
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .permission-table{
    .left-col{
      width: 25% !important;
    }
    .right-col{
      width: 75% !important;
    }
  }
  .permission-tab {
    .detail-panel {
      padding: 40px 75px;
      .panel-header{
        margin-bottom: 36px;
      }
    }
  }
  .permission-group{
    padding-left: 36px;
    padding-right: 36px;
  }
  .page-header{
    padding: '30px 40px';
    .page-header-inner {
      padding: 30px;
    }
  }
 }