.payment-success-banner {
  .banner-container {
    width: 50%;
    padding: 3rem 4rem;
    @media (min-width: 900px) and (max-width: 1300px) {
      width: 60%;
    }
    @media (min-width: 700px) and (max-width: 900px) {
      width: 80%;
    }
    @media (max-width: 700px) {
      width: 100%;
    }
  }
}

.success-pulse {
  //   background: linear-gradient(#8a82fb, #407ed7);
  background: #049fdb;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  place-items: center;
  font-size: 50px;
  height: 5.5rem;
  width: 5.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: '50px';
  color: #ffffff;
  z-index: 1;

  i {
    font-size: 5rem;
  }

  @media (min-width: 900px) and (max-width: 1300px) {
    height: 4.5rem;
    width: 4.5rem;
    i {
      font-size: 4rem;
    }
  }
  @media (min-width: 700px) and (max-width: 900px) {
    height: 4rem;
    width: 4rem;
    i {
      font-size: 3.5rem;
    }
  }
  @media (max-width: 700px) {
    height: 4.5rem;
    width: 4.5rem;
    i {
      font-size: 4rem;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #049fdb;
    border-radius: 50%;
    z-index: -1;
    opacity: 0.7;
  }

  &:before {
    animation: pulse 2s ease-out infinite;
  }

  &:after {
    animation: pulse 2s 1s ease-out infinite;
  }
}

.rejected-pulse {
  //   background: linear-gradient(#8a82fb, #407ed7);
  background: #dc3545;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  place-items: center;
  font-size: 50px;
  height: 5.5rem;
  width: 5.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: '50px';
  color: #ffffff;
  z-index: 1;

  i {
    font-size: 5rem;
  }

  @media (min-width: 900px) and (max-width: 1300px) {
    height: 4.5rem;
    width: 4.5rem;
    i {
      font-size: 4rem;
    }
  }
  @media (min-width: 700px) and (max-width: 900px) {
    height: 4rem;
    width: 4rem;
    i {
      font-size: 3.5rem;
    }
  }
  @media (max-width: 700px) {
    height: 4.5rem;
    width: 4.5rem;
    i {
      font-size: 4rem;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #dc3545;
    border-radius: 50%;
    z-index: -1;
    opacity: 0.7;
  }

  &:before {
    animation: pulse 2s ease-out infinite;
  }

  &:after {
    animation: pulse 2s 1s ease-out infinite;
  }
}

@keyframes pulse {
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
