.hero-form {
  .form-control {
    background-color: transparent !important;
    border-radius: 16px;
  }
  textarea.form-control {
    background-color: transparent !important;
    border-radius: 16px;
    height: 120px !important;
  }
}
