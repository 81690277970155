// Gradients

.bg-warm-flame {
  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%) !important;
}

.bg-night-fade {
  background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%) !important;
}

.bg-sunny-morning {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%) !important;
}

.bg-tempting-azure {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%) !important;
}

.bg-amy-crisp {
  background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%) !important;
}

.bg-heavy-rain {
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%) !important;
}

.bg-mean-fruit {
  background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%) !important;
}

.bg-malibu-beach {
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%) !important;
}

.bg-deep-blue {
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%) !important;
}

.bg-ripe-malin {
  background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%) !important;
}

.bg-arielle-smile {
  background-image: radial-gradient(circle 248px at center, #16d9e3 0%, #30c7ec 47%, #46aef7 100%) !important;
}

.bg-plum-plate {
  background-image: linear-gradient(135deg, #667eea 0%, #9e163c 100%) !important;
}

.bg-happy-fisher {
  background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%) !important;
}

.bg-happy-itmeo {
  background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%) !important;
}

.bg-mixed-hopes {
  background-image: linear-gradient(to top, #c471f5 0%, #fa71cd 100%) !important;
}

.bg-strong-bliss {
  background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%) !important;
}

.bg-grow-early {
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%) !important;
}

.bg-love-kiss {
  background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%) !important;
}

.bg-premium-dark {
  background-image: linear-gradient(to right, #434343 0%, black 100%) !important;
}

.bg-happy-green {
  background-image: linear-gradient(to bottom, #00b09b, #96c93d) !important;
}

.bg-vicious-stance {
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%) !important;
}

.bg-midnight-bloom {
  background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%) !important;
}

.bg-night-sky {
  background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%) !important;
}

.bg-slick-carbon {
  background-image: linear-gradient(to bottom, #323232 0%, #3f3f3f 40%, #1c1c1c 150%),
    linear-gradient(to top, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.25) 200%) !important;
  background-blend-mode: multiply;
}

.bg-royal {
  background-image: linear-gradient(to right, #141e30, #243b55) !important;
}

.bg-asteroid {
  background-image: linear-gradient(to right, #0f2027, #203a43, #2c5364) !important;
}

.bg-transparent {
  background: transparent !important;
}

// Gradients

.bg-warm-flame-text {
  // color: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%) !important;
  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-night-fade-text {
  // color: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%) !important;
  background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-sunny-morning-text {
  // color: linear-gradient(120deg, #f6d365 0%, #fda085 100%) !important;
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-tempting-azure-text {
  // color: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%) !important;
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-amy-crisp-text {
  // color: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%) !important;
  background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-heavy-rain-text {
  // color: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%) !important;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-mean-fruit-text {
  // color: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%) !important;
  background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-malibu-beach-text {
  // color: linear-gradient(to right, #4facfe 0%, #00f2fe 100%) !important;
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-deep-blue-text {
  // color: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%) !important;
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-ripe-malin-text {
  // color: linear-gradient(120deg, #f093fb 0%, #f5576c 100%) !important;
  background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-arielle-smile-text {
  // color: radial-gradient(circle 248px at center, #16d9e3 0%, #30c7ec 47%, #46aef7 100%) !important;
  background-image: linear-gradient(circle 248px at center, #16d9e3 0%, #30c7ec 47%, #46aef7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-plum-plate-text {
  // color: linear-gradient(135deg, #667eea 0%, #764ba2 100%) !important;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-happy-fisher-text {
  // color: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%) !important;
  background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-happy-itmeo-text {
  // color: linear-gradient(180deg, #2af598 0%, #009efd 100%) !important;
  background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-mixed-hopes-text {
  // color: linear-gradient(to top, #c471f5 0%, #fa71cd 100%) !important;
  background-image: linear-gradient(to top, #c471f5 0%, #fa71cd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-strong-bliss-text {
  // color: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%) !important;
  background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-grow-early-text {
  // color: linear-gradient(to top, #0ba360 0%, #3cba92 100%) !important;
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-love-kiss-text {
  // color: linear-gradient(to top, #ff0844 0%, #ffb199 100%) !important;
  background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-premium-dark-text {
  // color: linear-gradient(to right, #434343 0%, black 100%) !important;
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-happy-green-text {
  // color: linear-gradient(to bottom, #00b09b, #96c93d) !important;
  background-image: linear-gradient(to bottom, #00b09b, #96c93d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-vicious-stance-text {
  // color: linear-gradient(60deg, #29323c 0%, #485563 100%) !important;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-midnight-bloom-text {
  // color: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%) !important;
  background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-night-sky-text {
  // color: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%) !important;
  background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-slick-carbon-text {
  // color: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%) !important;
  background-image: linear-gradient(to bottom, #323232 0%, #3f3f3f 40%, #1c1c1c 150%),
    linear-gradient(to top, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.25) 200%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-blend-mode: multiply;
}

.bg-royal-text {
  // color: linear-gradient(to right, #141e30, #243b55) !important;
  background-image: linear-gradient(to right, #141e30 0%, #243b55 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-asteroid-text {
  // background: linear-gradient(to right, #0f2027 0%, #203a43 50%, #2c5364 100%) !important;
  background-image: linear-gradient(to right, #0f2027 0%, #203a43 50%, #2c5364 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-transparent-text {
  color: transparent !important;
}

.bg-primary-text {
  color: #545cd8;
}
.bg-primary {
  i {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}
.bg-secondary-text {
  color: #6c757d;
}
.bg-secondary {
  i {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}

.bg-success-text {
  color: #3ac47d;
}
.bg-success {
  
  i {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}
.bg-info-text {
  color: #049FDB;
}

.bg-info {
  i {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}
.bg-warning-text {
  color: #f7b924;
}
.bg-warning {
  i {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}
.bg-danger-text {
  color: #d92550;
}

.bg-danger {
  i {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}
.bg-light {
  background-color: #f5f5f5 !important;
}
.bg-light-text {
  color: #d9d9d9;
}
.bg-white-text {
  color: #fff;
}
.bg-dark-text {
  color: #343a40;
}
.bg-focus-text {
  color: #444054;
}
.bg-alternat-text {
  color: #83588a;
}
