.inputs input:focus,
    .inputs input:active,
    .inputs input:not(:placeholder-shown) {
      border-color: #049FDB;
      outline-color: #049FDB;
    }

    .inputs input:placeholder-shown {
      border-color: rgb(218, 218, 218);
    }

		.group {
			position: relative;
		}

.input {
//  font-size: 16px;
 padding: 15px 10px 15px 5px;
 display: block;
 width: 100%;
 border: none;
 border-bottom: 1px solid #049FDB;
 background: transparent;
}

.input:focus {
 outline: none;
}

.label {
 color: #999;
 font-size: 18px;
 font-weight: normal;
 position: absolute;
 pointer-events: none;
 left: 5px;
 top: 15px;
 transition: 0.2s ease all;
 -moz-transition: 0.2s ease all;
 -webkit-transition: 0.2s ease all;
}

.input:focus ~ label, .input:valid ~ label {
 top: -10px;
 font-size: 14px;
 color: #049FDB;
}

.bar {
 position: relative;
 display: block;
 width: 100%;
}

.bar:before, .bar:after {
 content: '';
 height: 2px;
 width: 0;
 bottom: 1px;
 position: absolute;
 background: #049FDB;
 transition: 0.2s ease all;
 -moz-transition: 0.2s ease all;
 -webkit-transition: 0.2s ease all;
}

.bar:before {
 left: 50%;
}

.bar:after {
 right: 50%;
}

.input:focus ~ .bar:before, .input:focus ~ .bar:after, .input:not(:placeholder-shown) ~ .bar:after, .input:not(:placeholder-shown) ~ .bar::before {
 width: 50%;
}

.highlight {
 position: absolute;
 height: 60%;
 width: 50%;
 top: 25%;
 left: 0;
 pointer-events: none;
 opacity: 0.5;
}

.input:focus ~ .highlight {
 animation: inputHighlighter 0.3s ease;
}

@keyframes inputHighlighter {
 from {
  background: #049FDB;
 }

 to {
  width: 0;
  background: transparent;
 }
}