.coupen-input {
	width: 13rem;
	margin-right: 1rem;
}

@media (min-width: 900px) and (max-width: 1500px){
.coupen-input {
	width: 8rem;
	margin-right: 0.5rem;
}
}

@media  (max-width: 900px){
.coupen-input {
	width: 7rem;
	margin-right: 0.5rem;
}
}