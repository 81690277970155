.booking-btn {
  padding: 1rem 4rem !important;
}

@media (min-width: 991px) and (max-width: 1500px) {
  .booking-btn {
    padding: 0.6rem 2rem !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .booking-btn {
    padding: 0.5rem 3rem !important;
  }
}

@media (max-width: 991px) {
  .booking-btn {
    padding: 0.5rem 1rem !important;
  }
}
