*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  // background-color: #e5e5fa;
}

.wizard-pulse {
  //   background: linear-gradient(#8a82fb, #407ed7);
  background: #049fdb;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  place-items: center;
  font-size: 50px;
  height: 50px;
  width: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: '50px';
  color: #ffffff;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #049fdb;
    border-radius: 50%;
    z-index: -1;
    opacity: 0.7;
  }

  &:before {
    animation: pulse 2s ease-out infinite;
  }

  &:after {
    animation: pulse 2s 1s ease-out infinite;
  }
}

.wizard-tab-visited {
  background: #049fdb;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  place-items: center;
  font-size: 50px;
  height: 50px;
  width: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: '50px';
  color: #ffffff;
  z-index: 1;
}

.wizard-tab-unvisited {
  background: rgb(168, 174, 187);
  // margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  place-items: center;
  font-size: 50px;
  height: 50px;
  width: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: '50px';
  color: #ffffff;
  z-index: 1;
  // width: min-content;
}

@keyframes pulse {
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
