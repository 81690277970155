.editCMSMainTitle {
  background-color: #e9e7e9;

  padding: 10px 22px 10px 22px;
  gap: 10px;
  border: 1px 0px 0px 0px;
  // opacity: 0px;
  border: none;
  cursor: pointer;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  top: -10px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
}