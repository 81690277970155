// typography.scss

// Base font sizes for tags
$font-sizes: (
  h1: 40px,
  h2: 54px,
  h3: 48px,
  h4: 36px,
  h5: 30px,
  h6: 24px,
  p: 24px,
);

// Font sizes for large screens (≥ 1500px)
$font-sizes-lg: (
  // h1: 64px,
  // h2: 60px,
  // h3: 52px,
  // h4: 40px,
  // h5: 30px,
  // h6: 24px,
  // p: 24px,
  h1: 30px,
  h2: 28px,
  h3: 26px,
  h4: 24px,
  h5: 22px,
  h6: 20px,
  p: 22px,
  li: 22px
);

// Font sizes for medium screens (≥ 1200px and < 1500px)
$font-sizes-md: (
  // h1: 40px,
  // h2: 36px,
  // h3: 30px,
  // h4: 24px,
  // h5: 20px,
  // h6: 18px,
  // p: 16px,
  h1: 40px,
  h2: 30px,
  h3: 40px,
  h4: 24px,
  h5: 22px,
  h6: 18px,
  p: 18px,
  li: 18px
);

// Font sizes for small screens (≥ 992px and < 1200px)
$font-sizes-sm: (
  // h1: 48px,
  // h2: 40px,
  // h3: 30px,
  // h4: 26px,
  // h5: 24px,
  // h6: 20px,
  // p: 18px,
  h1: 40px,
  h2: 42px,
  h3: 36px,
  h4: 28px,
  h5: 24px,
  h6: 18px,
  p: 18px,
  li: 18px
);

// Font sizes for extra small screens (≥ 768px and < 992px)
// $font-sizes-xs: (
//   // h1: 40px,
//   // h2: 32px,
//   // h3: 30px,
//   // h4: 28px,
//   // h5: 24px,
//   // h6: 20px,
//   // p: 18px,
//   h1: 40px,
//   h2: 54px,
//   h3: 48px,
//   h4: 36px,
//   h5: 30px,
//   h6: 24px,
//   p: 24px
// );

// Font sizes for mobile screens (< 768px)
// $font-sizes-mobile: (
//   h1: 38px,
//   h2: 30px,
//   h3: 28px,
//   h4: 24px,
//   h5: 20px,
//   h6: 18px,
//   p: 16px,
// );

// Base font sizes for custom classes
$custom-font-sizes: (
  h1: 40px,
  h2: 54px,
  h3: 48px,
  h4: 36px,
  h5: 30px,
  h6: 24px,
  p: 24px,
  body1: 22px,
  body2: 20px,
  body_40: 40px,
  subtitle1: 18px,
  subtitle2: 16px,
);

// Custom classes for large screens (≥ 1600px)
$custom-font-sizes-lg: (
  h1: 30px,
  h2: 28px,
  h3: 26px,
  h4: 24px,
  h5: 22px,
  h6: 20px,
  p: 22px,
  body1: 20px,
  body2: 18px,
  subtitle1: 16px,
  subtitle2: 14px,
  body_40: 30px,
);

// Custom classes for medium screens (≥ 1200px and < 1920px)
$custom-font-sizes-md: (
  h1: 40px,
  h2: 30px,
  h3: 40px,
  h4: 22px,
  h5: 20px,
  h6: 18px,
  p: 18px,
  body1: 18px,
  body2: 16px,
  subtitle1: 14px,
  subtitle2: 12px,
  body_40: 30px,
);

// Custom classes for small screens (≥ 992px and < 1200px)
$custom-font-sizes-sm: (
  h1: 40px,
  h2: 42px,
  h3: 36px,
  h4: 28px,
  h5: 24px,
  h6: 18px,
  p: 18px,
  body1: 18px,
  body2: 16px,
  subtitle1: 14px,
  subtitle2: 12px,
  body_40: 24px,
);

// Custom classes for extra small screens (≥ 768px and < 992px)
// $custom-font-sizes-xs: (
//   h1: 40px,
//   h2: 54px,
//   h3: 48px,
//   h4: 36px,
//   h5: 30px,
//   h6: 24px,
//   p: 24px,
//   body1: 24px,
//   body2: 16px,
//   subtitle1: 14px,
//   subtitle2: 12px,
//   body_40: 28px,
// );

// Custom classes for mobile screens (< 768px)
// $custom-font-sizes-mobile: (
//   h1: 38px,
//   h2: 30px,
//   h3: 28px,
//   h4: 24px,
//   h5: 20px,
//   h6: 18px,
//   body1: 15px,
//   body2: 14px,
//   subtitle1: 12px,
//   subtitle2: 10px,
//   body_40: 24px,
// );

// Apply base font sizes
@each $class, $size in $custom-font-sizes {
  .#{$class} {
    font-size: $size;
    p,
    li {
      font-size: $size;
    }
  }
}

@each $tag, $size in $font-sizes {
  #{$tag} {
    font-size: $size;
  }
}

// Apply large screen font sizes
@media (min-width: 1750px) {
  @each $class, $size in $custom-font-sizes-lg {
    .#{$class} {
      font-size: $size;
      p,
      li {
        font-size: $size;
      }
    }
  }
  @each $tag, $size in $font-sizes-lg {
    #{$tag} {
      font-size: $size;
    }
  }
}

// Apply medium screen font sizes
// @media (min-width: 1500px) and (max-width: 1700px) {
@media (min-width: 900px) and (max-width: 1750px) {
  @each $class, $size in $custom-font-sizes-md {
    .#{$class} {
      font-size: $size;
      p,
      li {
        font-size: $size;
      }
    }
  }
  @each $tag, $size in $font-sizes-md {
    #{$tag} {
      font-size: $size;
    }
  }
}

// Apply small screen font sizes
// @media (min-width: 992px) and (max-width: 1500px) {
//   @each $class, $size in $custom-font-sizes-sm {
//     .#{$class} {
//       font-size: $size;
//       p {
//         font-size: $size;
//       }
//     }
//   }
//   @each $tag, $size in $font-sizes-sm {
//     #{$tag} {
//       font-size: $size;
//     }
//   }
// }

// Apply extra small screen font sizes
// @media (min-width: 768px) and (max-width: 991px) {
//   @each $tag, $size in $font-sizes-xs {
//     @each $class, $size in $custom-font-sizes-xs {
//       .#{$class} {
//         font-size: $size;
//         p {
//           font-size: $size;
//         }
//       }
//     }
//     #{$tag} {
//       font-size: $size;
//     }
//   }
// }

// Apply mobile screen font sizes
@media (max-width: 900px) {
  @each $class, $size in $custom-font-sizes-sm {
    .#{$class} {
      font-size: $size;
      p,
      li {
        font-size: $size;
      }
    }
  }
  @each $tag, $size in $font-sizes-sm {
    #{$tag} {
      font-size: $size;
    }
  }
}

.subtitle1::placeholder {
  font-size: inherit; /* Inherits font-size from the input's subtitle1 class */
}

.content-widget {
  h1,
  h2,
  h3 {
    color: #049fdb;
    font-weight: 700;
  }

  h4,
  h5,
  h6 {
    font-weight: 400;
  }
  ul {
    color: #8e8e8e;
    font-weight: 100;
    padding-left: 1rem;
    li {
      color: #8e8e8e;
      font-weight: 100;
      padding-bottom: 0.6rem;
    }
  }
  p {
    color: #8e8e8e;
    font-weight: 100;
  }
  .p {
    color: #8e8e8e;
    font-weight: 100;
  }
}
.h1,
.h2,
.h3 {
  color: #049fdb;
  font-weight: 700;
}
.rich-text {
  font-weight: 100;
}
