.old-booking-btn {
  padding: 1rem 2.5rem !important;
}

.old-booking-container {
  .primary-color {
  * {
    color: rgb(4, 159, 219);
  }

}
}


@media (min-width: 991px) and (max-width: 1500px) {
  .old-booking-btn {
    padding: 0.6rem 2.5rem !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .old-booking-btn {
    padding: 0.5rem 2.5rem !important;
  }
}

@media (max-width: 991px) {
  .old-booking-btn {
    padding: 0.5rem 3rem !important;
  }
}
