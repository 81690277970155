.hero_search {
  .main_content {
    padding: 2rem;
    margin-block: 4rem;
    background: rgb(255 255 255 / 60%);
    width: fit-content;
    max-width: 60%;
    border: 2px solid #00000009;
  }
	.icon {
		font-size: 6rem;
	}
}


@media (min-width: 900px) and (max-width: 1300px) {
	.main_content {
		max-width: 60% !important;
	}
	.icon {
		font-size: 5rem !important;
	}
}
@media (min-width: 700px) and (max-width: 900px) {
	.main_content {
		max-width: 80% !important;
	}
	.icon {
		font-size: 4rem !important;
	}
}
@media (max-width: 700px)  {
	.main_content {
		max-width: 100% !important;
	}
	.icon {
		font-size: 4rem !important;
	}
}
