.rounded-input-container {
  border: 1px solid #ced4da;
  padding: 0.1rem 1rem;
  background-color: #fff;
  border-radius: 50px;
  width: 100%;
  input {
    background-color: transparent !important;
    border: none;
    flex: 1;
  }

  input:focus-visible {
    border: none;
    outline: none;
  }
}
.black-placeholder {
  color: black;
  font-size: 0.75rem;
  border: 1px solid black !important;

  &::placeholder {
    color: black !important; // Change placeholder color to black
    font-size: 0.75rem;
  }
}

.red-placeholder {
  &::placeholder {
    color: red !important;
  }
}
.rounded-select {
  select {
    border: 1px solid #ced4da;
    border-radius: 50px;
    width: 100%;
    padding: 0.8rem 1rem;
  }
}


