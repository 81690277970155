.carousel {
  .content-wrapper {
    padding-top: 13rem;
    padding-bottom: 10rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 7rem;
    padding-right: 6rem;
    max-width: 1440px;
    width: 1440px;
    display: flex;
    // justify-content: center;
    align-items: center;
    // min-width: 1440px;
    // margin: auto
  }
}

@media (max-width: 900px) {
  .carousel .content-wrapper {
    padding: 1.2rem !important;
  }
}
