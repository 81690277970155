// payment-success-action
@media (max-width: 1450px) {
   .payment-success-action {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1450px) {
   .payment-success-action {
    grid-template-columns: 1fr 1fr;
  }
}

