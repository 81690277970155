.rounded-input-icon {
  font-size: 18px;
  height: fit-content !important;


  @media (min-width: 1750px) {
    font-size: 18px !important;
    padding: 0.8rem 2.5rem !important;
  }

  @media (min-width: 900px) and (max-width: 1750px) {
    font-size: 14px !important;
    padding: 0.6rem 2.5rem !important;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    padding: 0.5rem 2.5rem !important;
  }
}

.rounded-input {
  font-size: 18px;
  height: fit-content !important;
		border-radius: 30px !important;
		border: 1px solid #ced4da;

  @media (min-width: 1750px) {
    font-size: 18px !important;
    padding: 0.8rem 1rem !important;
  }

  @media (min-width: 900px) and (max-width: 1750px) {
    font-size: 14px !important;
    padding: 0.6rem 0.7rem !important;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    padding: 0.5rem 0.7rem !important;
  }
}
